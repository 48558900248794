<template>
  <div
    v-loading="loading"
    class="bg-white fixed w-full h-max-content z-10 rounded-xl max-w-25 tariff-info modal-shadow"
  >
    <div class="relative">
      <ModalWrapper
        :title="$t('settings.tariff.title')"
        :submit-text="$t('save')"
        no-footer
        titleSize="24"
        @onClose="$emit('onClose')"
      >
        <template #subtitle>
          <div
            class="flex items-center justify-between mt-3 py-1 text-grey-200 text-xs font-normal divider divider-before tariff-info__balance"
          >
            <span>{{ $t('settings.tariff.on_balance') }}</span>
            <span>{{ companyBilling.amount }} {{ templateCurrency }}</span>
          </div>
        </template>
        <div class="flex flex-col gap-4">
          <div class="flex flex-col gap-2">
            <span class="text-grey-200 font-bold">
              {{ $t('settings.tariff.accounting_type') }}
            </span>
            <span>{{ accountingType }}</span>
          </div>
          <div class="flex items-center gap-8">
            <div class="flex flex-col gap-2 w-6/12">
              <span class="text-grey-200 font-bold">
                {{ $t('settings.tariff.object_count') }}
              </span>
              <span>{{ companyBilling.tariff.units }}</span>
            </div>
            <div class="flex flex-col gap-2">
              <span class="text-grey-200 font-bold">
                {{ $t('settings.tariff.object_price') }}
              </span>
              <span>
                {{ companyBilling.tariff.price }} {{ templateCurrency }}
              </span>
            </div>
          </div>

          <div class="flex items-center gap-8">
            <div class="flex flex-col gap-2 w-6/12">
              <span class="text-grey-200 font-bold">
                {{ $t('settings.tariff.period_start') }}
              </span>
              <span>{{ companyBilling.start_date }}</span>
            </div>
            <div class="flex flex-col gap-2">
              <span class="text-grey-200 font-bold">
                {{ $t('settings.tariff.payment_period') }}
              </span>
              <span>{{ paymentPeriod }}</span>
            </div>
          </div>

          <div class="flex items-center gap-8">
            <div class="flex flex-col gap-2 w-6/12">
              <span class="text-grey-200 font-bold">
                {{ $t('settings.tariff.autopay') }}
              </span>
              <div class="flex checkbox items-center gap-1">
                <el-checkbox
                  :value="isAutopayActive"
                  :disabled="isAutoPayDisabled"
                  @change="setAutoPay"
                >
                <span
                  :class="{
                    'text-grey-300': !companyBilling.auto_pay.card_num_suffix
                  }"
                  >{{ $t('settings.tariff.turn_on_autopay') }}</span
                >
                </el-checkbox>
              </div>
            </div>
            <div class="flex flex-col gap-2">
              <span class="text-grey-200 font-bold">
                {{ $t('settings.tariff.card') }}
              </span>
              <div
                v-if="companyBilling.auto_pay.card_num_suffix"
                class="flex items-center text-sizeTen"
              >
                <span
                  v-for="(_, idx) in 12"
                  :key="idx"
                  :class="{ 'mr-1': (idx + 1) % 4 === 0 }"
                  v-html="asterisk"
                />
                <span class="mr-1 text-sm">
                  {{ companyBilling.auto_pay.card_num_suffix }}
                </span>
                <div class="flex items-center gap-1">
                  <EditIcon
                    class="cursor-pointer"
                    @click.native="openCardModal('change')"
                  />
                  <DeleteIcon
                    class="cursor-pointer"
                    @click.native="deleteCard"
                  />
                </div>
              </div>
              <div v-else>
                <span class="mr-1">
                  {{ $t('settings.tariff.no_card_added') }}
                </span>
              </div>
            </div>
          </div>
          <div class="bg-lightGrey-100 flex flex-col gap-1 p-4 rounded-xl">
            <span v-if="companyBilling.valid_to">
              {{ $t('settings.tariff.next_write_off') }}
              <span v-html="nextPayment" />
            </span>
            <span class="text-grey-100 font-bold">
              {{ $t('settings.tariff.last_period_sum') }}
              {{ companyBilling.tariff.last_total }}
              {{ templateCurrency }}
            </span>
            <div
              v-if="companyBilling.auto_pay.is_active"
              :class="[
                'flex',
                'items-center',
                'gap-2',
                'p-3',
                'rounded-xl',
                'text-xs',
                'font-normal',
                isAutoPayError
                  ? 'bg-warningBg text-warningText'
                  : 'bg-blue-100 text-blue-400'
              ]"
            >
              <div>
                <NoteInfoIcon v-if="!isAutoPayError" />
                <NoteInfoWarnIcon v-else />
              </div>
              <div class="break-normal text-left">
                {{
                  isAutoPayError
                    ? $t('settings.tariff.errors.autopay')
                    : $t('settings.tariff.active_autopay')
                }}
              </div>
            </div>
          </div>
        </div>
      </ModalWrapper>
      <AddCard
        v-if="isAddCard"
        :type="cardModalType"
        @onClose="isAddCard = false"
        class="tariff-info__add-card"
      />
<!--      <Overlay-->
<!--        :visible="isAddCard"-->
<!--        :classes="['rounded-xl']"-->
<!--        @click.native="isAddCard = false"-->
<!--      />-->
    </div>
  </div>
</template>
<script setup>
import { computed, ref, getCurrentInstance } from 'vue'
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import { companyApi } from '@/api'
import companyStatuses from '@/enums/companyStatuses'
import allRoles from '@/enums/roles.js'

import ModalWrapper from '@/components/companyControlsCenter/components/ControlsModalWrapper.vue'
import AddCard from '@/components/companyControlsCenter/components/TariffInformation/AddCard.vue'
import Overlay from '@/components/companyControlsCenter/components/ControlsOverlay.vue'

import EditIcon from '@/components/companyControlsCenter/assets/icons/edit.vue'
import DeleteIcon from '@/components/companyControlsCenter/assets/icons/delete.vue'
import NoteInfoIcon from '@/components/companyControlsCenter/assets/icons/note-info.vue'
import NoteInfoWarnIcon from '@/components/companyControlsCenter/assets/icons/note-info_warn.vue'

const { useGetters, useMutations } = createVuexHelpers()

const { companyBilling, roles, user } = useGetters('login', [
  'companyBilling',
  'roles',
  'user'
])

const { SET_AUTO_PAY } = useMutations('login', ['SET_AUTO_PAY'])

const $t = useI18n()
const instance = getCurrentInstance()

const asterisk = '&#8727;'
const tilde = '&#126;'
const tariffTypeAllUnits = 'charge_all_units'
const isAddCard = ref(false)
const cardModalType = ref('add')

const isAdminOrHigher = computed(() => {
  return (
    roles.value.key === allRoles.ADMIN ||
    roles.value.key === allRoles.SUPERVISOR ||
    roles.value.key === allRoles.TARIFF_EDITOR ||
    roles.value.key === allRoles.CONTROLLER
  )
})

const isAutoPayDisabled = computed(() => {
  return (
    !companyBilling.value.auto_pay.card_num_suffix ||
    !isAdminOrHigher.value ||
    !user.value.active_company.billing?.public_offer_path_auto_pay
  )
})

const isAutoPayError = computed(() => {
  return companyBilling.value.auto_pay.error_msg
})

const accountingType = computed(() => {
  return companyBilling.value.tariff.type === tariffTypeAllUnits
    ? $t('company.by_number_objects')
    : $t('company.by_number_objects_sent_data')
})

const templateCurrency = computed(() => {
  return companyBilling.value.currency.code === 'RUB'
    ? $t('tariff.rouble')
    : companyBilling.value.currency.display_name
})

const paymentPeriod = computed(() => {
  const length = companyBilling.value.tariff.length
  let days
  if (length === 1) {
    days = $t('tariff.one_day')
  } else if (length >= 2 && length <= 4) {
    days =
      me.lang.key === 'en'
        ? $t('tariff.days')
        : $t('tariff.less_than_four_days')
  } else {
    days = $t('tariff.days')
  }
  return `${length} ${days}`
})

const isAutopayActive = ref(companyBilling.value.auto_pay.is_active)

const nextPayment = computed(() => {
  if (companyBilling.value.company_status === companyStatuses.BLOCKED) return ''
  console.log(companyBilling.value.valid_to, 'sssssss')
  const validToSubstring = companyBilling.value.valid_to.substring(
    0,
    companyBilling.value.valid_to.length - 8
  )

  const { type, total } = companyBilling.value.tariff

  const paymentAmount =
    type === tariffTypeAllUnits ? total : `${tilde + tilde + tilde}`

  return `${validToSubstring} (${paymentAmount} ${templateCurrency.value})`
})

const setAutoPay = async (value) => {
  try {
    isAutopayActive.value = value
    loading.value = true

    if (value) {
      await companyApi.startAutoPay()

      companyBilling.value.auto_pay.is_active = value
      SET_AUTO_PAY(value)
    } else {
      await companyApi.stopAutoPay()

      SET_AUTO_PAY(value)
    }

    instance.proxy.$showSuccess($t('settings.tariff.success.update_autopay'))
  } catch (err) {
    isAutopayActive.value = !value
    instance.proxy.$showError(err.response.data.message)
  } finally {
    loading.value = false
  }
}

const deleteCard = async () => {
  try {
    loading.value = true

    await companyApi.deleteCard()

    isAutopayActive.value = false
    SET_AUTO_PAY(false)

    instance.proxy.$showSuccess($t('settings.tariff.success.delete_card'))
  } catch (err) {
    instance.proxy.$showError(err)
  } finally {
    loading.value = false
  }
}

const openCardModal = (type) => {
  isAddCard.value = true
  cardModalType.value = type
}

const loading = ref(false)
</script>
<style lang="scss">
@import '../../assets/styles/TariffInformation.scss';
</style>
