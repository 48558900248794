<template>
  <div
      class="settings-container"
      ref="wrapper"
      tabindex="0"
      @keydown="keyDownHandler"
      @keyup="keyUpHandler"
  >
    <div
        class="flex justify-between items-center gap-3 bg-lightGrey-200 rounded-xl p-four-tenths pr-4 cursor-pointer mb-3"
        @click="openUserInfoModal"
    >
      <div class="flex items-center gap-3">
        <div class="avatar w-12 h-12 rounded-full">
          <img :src="avatar" alt="avatar" class="rounded-full w-full h-full" />
        </div>
        <div class="user-info">
          <el-tooltip
              :open-delay="700"
              :content="name"
              :offset="2"
              :visible-arrow="false"
              effect="dark"
              placement="bottom"
          >
            <span class="text-base font-semibold max-w-8 truncate inline-block">
              {{ name }}
            </span>
          </el-tooltip>
          <p class="text-xs text-grey-300 font-normal">{{ roles.value }}</p>
        </div>
      </div>
      <div class="expand-icon">
        <img :src="ArrowMenu" alt="arrow" />
      </div>
    </div>
    <div class="card company-card bg-lightGrey-200 rounded-xl mb-4">
      <div class="relative">
        <span class="input-label">{{ $t('settings.main.company') }}</span>
        <el-select
            v-model="activeCompany.id"
            :popper-append-to-body="false"
            filterable
            class="w-full"
            @change="changeCompany"
        >
          <el-option
              v-for="item in companies"
              :key="item.id"
              :label="item.name"
              :value="item.id"
          >
            <el-tooltip
                :enterable="false"
                :content="item.name"
                :open-delay="300"
                :popper-options="popperOptions"
                ref="companySelectTooltipRef"
                class="item"
                effect="dark"
            >
              <div class="flex items-center gap-2">
                <LockIcon
                    v-if="
                    item.billing &&
                    item.billing.company_status === companyStatuses.BLOCKED
                  "
                />
                <span>{{ item.name }}</span>
              </div>
            </el-tooltip>
          </el-option>
        </el-select>
      </div>
    </div>
    <div>
      <div v-for="(section, idx) in sections" :key="idx" class="mb-4 section">
        <div v-if="section.access">
          <div v-if="section.title" class="flex justify-between mb-3">
            <span class="text-grey-100">
              {{ section.title }}
            </span>
            <span v-if="section.balance" class="text-grey-300 font-semibold">
              {{ section.balance }} {{ section.currency }}
            </span>
          </div>
          <div>
            <div
                v-for="(subtitle, index) in section.subtitles"
                :key="index"
                :class="{ 'mb-2': subtitle.access }"
                class="flex items-center text-blue-400 cursor-pointer"
            >
              <span
                  v-if="subtitle.access"
                  class="section-subtitle font-medium"
                  @click="subtitle.event ? subtitle.event() : () => {}"
              >
                {{ subtitle.text }}
              </span>
              <span v-if="subtitle.hot_key" class="text-grey-100 ml-1">
                {{ subtitle.hot_key }}
              </span>
              <img
                  v-if="subtitle.access && subtitle.icon"
                  :src="subtitle.icon"
                  class="ml-1"
                  alt="title icon"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-button
        class="objects-footer__cancel border-0 bg-roseWhite text-red-brightDelight rounded-md font-semibold"
        @click="logoutHandler"
    >
      {{ $t('settings.main.exit') }}
    </el-button>
    <UserInfoModal
        v-if="isUserInfoModal"
        @onClose="isUserInfoModal = false"
        @onShowHistory="
        $emit('onShowHistory', { objects: 'users', objectId: user.id })
      "
    />

    <transition name="el-fade-in">
      <DisplaySettings
          v-if="isDisplaySettings"
          @onClose="closeDisplaySettings"
      />
    </transition>
    <CompanySettings v-if="isCompanySettings" @onClose="closeCompanySettings" />
    <TariffInformation
        v-if="isTariffInformation"
        @onClose="isTariffInformation = false"
    />
    <BalanceTopUp v-if="isBalanceTopUp" @onClose="isBalanceTopUp = false" />
    <InviteUser v-if="isInviteUser" @onClose="isInviteUser = false" />
    <Support
        v-if="isSupport && getBranding.contacts"
        :contacts="contacts"
        @onClose="isSupport = false"
    />
    <Sessions v-if="isSessions" @close="isSessions = false" class="sessions" />
    <PaymentHistory
        v-if="isPaymentHistory"
        @close="isPaymentHistory = false"
        class="z-40 payments"
    />

    <Overlay
        :visible="needOverlay"
        position="fixed"
        @click.native="closeOverlay"
    />
  </div>
</template>
<script setup>
import {
  ref,
  defineEmits,
  computed,
  onBeforeUnmount,
  onMounted,
  provide
} from 'vue'

import UserInfoModal from './UserInfo/UserInfoModal.vue'
import DisplaySettings from './DisplaySettings/DisplaySettingsModal.vue'
import CompanySettings from './CompanySettings/CompanySettingsModal.vue'
import TariffInformation from './TariffInformation/TariffInformationModal.vue'
import BalanceTopUp from './Balance/BalanceTopUpModal.vue'
import InviteUser from './InviteUser/InviteUserModal.vue'
import Support from './Support/SupportModal.vue'
import Sessions from '@/components/sessions/sessions.vue'
import PaymentHistory from '@/views/main/components/PaymentHistory.vue'
import Overlay from '@/components/companyControlsCenter/components/ControlsOverlay.vue'
// --ICONS--
import Avatar from '@/components/companyControlsCenter/assets/images/Avatar.svg'
import ArrowMenu from '@/components/companyControlsCenter/assets/images/ArrowMenu.svg'
import AdminPanel from '@/assets/icons/AdminPanel.svg'
import LockIcon from '@/components/companyControlsCenter/assets/icons/lock.vue'
// --ICONS--

import {
  NEWS_UPDATES_URL,
  REFERENCE_URL,
  FINANCICAL_REPORT_URL
} from '@/constants'
import companyStatuses from '@/enums/companyStatuses'
import { createVuexHelpers } from 'vue2-helpers'
import { useI18n } from '@/hooks/useI18n'
import { useRouter } from 'vue2-helpers/vue-router'

import { closews } from '@/api/ws.js'
import { companyApi } from '@/api'
import { useStore } from '@/store/store.js'
const { useGetters, useActions, useMutations } = createVuexHelpers()

const {
  roles,
  companies,
  name,
  activeCompany,
  balance,
  currency,
  user,
  companyBilling,
  getBranding
} = useGetters('login', [
  'roles',
  'companies',
  'name',
  'activeCompany',
  'balance',
  'currency',
  'user',
  'companyBilling',
  'getBranding'
])

const { logout } = useActions('login', ['logout'])

const Store = useStore()

const { clearVuexMarkersUnitOnMap, clearVuexMarkersOnMap } = useMutations(
    'monitoringNew',
    ['clearVuexMarkersUnitOnMap', 'clearVuexMarkersOnMap']
)

const router = useRouter()
const $t = useI18n()
const emits = defineEmits(['isLogout', 'changeCompany', 'onClose'])
const popperOptions = {
  placement: 'bottom',
  boundariesElement: 'scrollParent'
}

const companySelectRef = ref(null)
const companySelectTooltipRef = ref(null)

const handleScroll = () => {
  companySelectTooltipRef.value.forEach((item) => item.hide())
}

const handleVisibleChange = (value) => {
  if (value) {
    // Dropdown menu is displayed, so attach the scroll event listener
    const dropdownMenu = companySelectRef.value.$el.querySelector(
        '.el-select-dropdown .el-select-dropdown__wrap'
    )
    dropdownMenu.addEventListener('scroll', handleScroll)
  } else {
    // Dropdown menu is hidden, so remove the scroll event listener
    const dropdownMenu = companySelectRef.value.$el.querySelector(
        '.el-select-dropdown .el-select-dropdown__wrap'
    )
    dropdownMenu.removeEventListener('scroll', handleScroll)
  }
}

const isUserInfoModal = ref(false)
const isDisplaySettings = ref(false)
const isCompanySettings = ref(false)
const isTariffInformation = ref(false)
const isBalanceTopUp = ref(false)
const isInviteUser = ref(false)
const isSupport = ref(false)
const isSessions = ref(false)
const isPaymentHistory = ref(false)
const wrapper = ref(null)
const isShiftPressed = ref(false)

const isAccessSettings = computed(()=>{
  return Store.getters['accessSettings/getIsAccessSettings']
})
const updateIsAccessSettings = () => {
  isAccessSettings.value = !isAccessSettings.value
}
provide('isAccessSettings', { isAccessSettings, updateIsAccessSettings })

const isAdminOrHigher = computed(() => {
  return (
      roles.value.key === 'ADMIN' ||
      roles.value.key === 'SUPERVISOR' ||
      roles.value.key === 'TARIFF_EDITOR' ||
      roles.value.key === 'CONTROLLER'
  )
})

const isSupervisorOrHigher = computed(() => {
  return (
      roles.value.key === 'SUPERVISOR' ||
      roles.value.key === 'TARIFF_EDITOR' ||
      roles.value.key === 'CONTROLLER'
  )
})

const openAdminPanel = () => {
  let id = null
  if (activeCompany.value.is_admin_panel) {
    const isActiveCompanyId = localStorage.getItem('active_company')
    id =
        isActiveCompanyId ||
        companies.value.filter((val) => !val.is_admin_panel)[0].id

    changeCompany(id)
  } else {
    id = companies.value.find((item) => item.is_admin_panel).id

    changeCompany(id)
  }
}

const openAdminPanelBeta = async () => {
  try {
    const data = await companyApi.getConfigs()

    const adminAppUrl = data.data['admin-app']
    window.open(adminAppUrl, '_blank')

    emits('onClose')
  } catch (err) {
    throw new Error(err)
  }
}

const changeCompany = (id) => {
  emits('changeCompany', id)
}

const downloadFinancicalReport = () => {
  window.open(FINANCICAL_REPORT_URL)
  emits('onClose')
}

const openUserInfoModal = () => {
  isUserInfoModal.value = true
}

const openDisplaySettings = () => {
  isDisplaySettings.value = true
}

const closeDisplaySettings = () => {
  isDisplaySettings.value = false

  wrapper.value.focus()
}

const openCompanySettings = () => {
  isCompanySettings.value = true
}

const closeCompanySettings = () => {
  isCompanySettings.value = false
}

const openTariffInformation = () => {
  isTariffInformation.value = true
}

const openBalanceTopUp = () => {
  isBalanceTopUp.value = true
}
const openAccessSettings = () => {
  setTimeout(() => {
    Store.commit('accessSettings/setIsAccessSettings',true)
  }, 300)
}
const openInviteUser = () => {
  isInviteUser.value = true
}

const openSessions = () => {
  isSessions.value = true
}

const openPaymentHistory = () => {
  isPaymentHistory.value = true
}

const openSupport = () => {
  isSupport.value = true
}

const openNewsAndUpdates = () => {
  window.open(NEWS_UPDATES_URL, '_blank')
}

const openReference = () => {
  window.open(REFERENCE_URL, '_blank')
}

const closeOverlay = () => {
  if (isCompanySettings.value) return (isCompanySettings.value = false)
  if (isTariffInformation.value) return (isTariffInformation.value = false)
  if (isBalanceTopUp.value) return (isBalanceTopUp.value = false)
  if (isAccessSettings.value){
    return (Store.commit('accessSettings/setIsAccessSettings',false))
  }
  if (isInviteUser.value) return (isInviteUser.value = false)
  if (isSupport.value) return (isSupport.value = false)
  if (isSessions.value) return (isSessions.value = false)
  if (isPaymentHistory.value) return (isPaymentHistory.value = false)
}

const needOverlay = computed(() => {
  return (
      isCompanySettings.value ||
      isTariffInformation.value ||
      isBalanceTopUp.value ||
      isAccessSettings.value ||
      isInviteUser.value ||
      isSupport.value ||
      isSessions.value ||
      isPaymentHistory.value
  )
})

const isCompanyBlocked = computed(() => {
  return companyBilling.value.company_status === companyStatuses.BLOCKED
})

const sections = [
  {
    title: '',
    subtitles: [
      {
        text: activeCompany.value._admin_panel
            ? $t('settings.main.go_back')
            : $t('settings.main.go_admin_panel'),
        access: isSupervisorOrHigher.value,
        event: openAdminPanel
      },
      {
        text: $t('settings.main.go_admin_panel_beta'),
        access: isSupervisorOrHigher.value,
        icon: AdminPanel,
        event: openAdminPanelBeta
      },
      {
        text: $t('settings.main.company_settings'),
        access: isAdminOrHigher.value && !isCompanyBlocked.value,
        event: openCompanySettings
      }
    ],
    access: isAdminOrHigher.value
  },
  {
    title: $t('settings.main.finance_and_balance'),
    balance: balance.value,
    currency: currency.value,
    subtitles: [
      {
        text: $t('settings.main.company_tariff'),
        access: companyBilling.value.tariff.price,
        event: openTariffInformation
      },
      {
        text: $t('settings.main.add_balance'),
        access: companyBilling.value.tariff.price && companyBilling.value.domain,
        event: openBalanceTopUp
      },
      {
        text: $t('settings.main.payments_history'),
        access: companyBilling.value.tariff.price,
        event: openPaymentHistory
      },
      {
        text: $t('settings.main.financical_report'),
        access: !isCompanyBlocked.value,
        event: downloadFinancicalReport
      }
    ],
    access: isAdminOrHigher.value
  },
  {
    title: $t('settings.main.work_with_company'),
    subtitles: [
      {
        text: $t('settings.main.access_settings'),
        access: isAdminOrHigher.value,
        event: openAccessSettings
      },
      {
        text: $t('settings.main.invite_user'),
        access: isAdminOrHigher.value,
        event: openInviteUser
      },
      {
        text: $t('settings.main.display_settings'),
        hot_key: '[Shift+S]',
        access: true,
        event: openDisplaySettings
      },
      {
        text: $t('settings.main.sessions'),
        access: isAdminOrHigher.value,
        event: openSessions
      }
    ],
    access: !isCompanyBlocked.value
  },
  {
    title: $t('settings.main.additional'),
    subtitles: [
      {
        text: $t('settings.main.news_and_updates'),
        access: !isCompanyBlocked.value,
        event: openNewsAndUpdates
      },
      {
        text: $t('settings.main.reference'),
        access: !isCompanyBlocked.value,
        event: openReference
      },
      {
        text: $t('settings.main.support'),
        access: getBranding.contacts,
        event: openSupport
      }
    ],
    access: true
  }
]

const avatar = computed(() => {
  const { photos } = user.value

  if (photos.length) {
    const avatar = photos.find((i) => i.url.indexOf('110') > 1)

    if (avatar && avatar.url) return `/` + `api_v1${avatar.url}`
  }

  return Avatar
})

const clearMonitoringLocalStorage = () => {
  const monitoringLocalStorage = JSON.parse(
      localStorage.getItem('keysUnitsForMonitorings')
  )
  if (monitoringLocalStorage && monitoringLocalStorage.length > 0) {
    localStorage.setItem('keysUnitsForMonitorings', JSON.stringify([]))
  }
}

const logoutHandler = async () => {
  // тут очистка маркеров с карты
  clearVuexMarkersUnitOnMap()
  clearVuexMarkersOnMap()

  closews()

  await logout()

  clearMonitoringLocalStorage()

  emits('isLogout', 'logout')
  router.push('/login')
}

const keyDownHandler = (event) => {
  if (event.keyCode === 16) isShiftPressed.value = true
}

const keyUpHandler = (event) => {
  if (event.keyCode === 16) isShiftPressed.value = false

  if (isShiftPressed.value && event.keyCode === 83) {
    isShiftPressed.value = false
    openDisplaySettings()
  }
}

onMounted(() => {
  wrapper.value.focus()
  wrapper.value.style.outline = 'none'
})

onBeforeUnmount(() => {
  isUserInfoModal.value = false
})
</script>

<style lang="scss">
@import '../assets/styles/main.scss';
</style>
