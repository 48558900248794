<template>
  <div
    v-loading="loading"
    class="bg-white fixed w-full h-max-content z-10 rounded-xl max-w-25 company-settings modal-shadow"
  >
    <ModalWrapper
      :title="$t('settings.company.title')"
      :header-classes="['divider']"
      :footer-classes="['divider-before']"
      :submit-text="$t('save')"
      titleSize="24"
      @onClose="$emit('onClose')"
      @onSubmit="saveCompanySettings"
    >
      <el-form
        :model="data"
        :ref="refs.companySettingsValidation"
        class="flex flex-col gap-4"
      >
        <div class="relative">
          <span class="object-basic__info__label input-label">{{
            $t('settings.company.name')
          }}</span>
          <el-form-item :rules="rules.name" prop="name">
            <el-input v-model="data.name" autocomplete="off" />
          </el-form-item>
        </div>
        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.company.time_zone') }}
          </span>
          <el-select
            v-model="data.timezone.key"
            :placeholder="$t('select')"
            class="w-full"
          >
            <el-option
              v-for="item in getTZ"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </div>
        <div class="relative">
          <span class="object-basic__info__label input-label">
            {{ $t('settings.company.integrator') }}
          </span>
          <el-select
            v-if="role !== allRoles.CONTROLLER"
            v-model="data.dealer.name"
            :disabled="role !== allRoles.CONTROLLER"
            class="w-full"
          />
          <el-select
            v-else
            v-model="data.dealer.id"
            filterable
            :disabled="role !== allRoles.CONTROLLER"
            class="w-full"
          >
            <el-option
              v-for="dealer in integrators"
              :key="dealer.id"
              :label="dealer.name"
              :value="dealer.id"
            />
          </el-select>
        </div>
        <div class="flex items-center justify-between gap-4 mt-6">
          <div class="flex items-center gap-1">
            <span>{{ $t('settings.company.exit_time') }}</span>
            <el-tooltip
              :open-delay="700"
              :content="$t('settings.company.exit_time_tooltip')"
              :offset="0"
              :visible-arrow="false"
              popper-class="tooltip"
              effect="dark"
              placement="bottom"
            >
              <NoteInfoIcon class="cursor-pointer" />
            </el-tooltip>
          </div>
          <el-input
            v-model.number="data.auto_kill_sessions"
            autocomplete="off"
            type="number"
            class="w-24 auto-kill"
          />
        </div>
        <div class="checkbox">
          <div class="flex items-center gap-1 mb-3">
            <el-checkbox
              v-model="data.strong_password"
              :label="$t('settings.company.high_security_mode')"
            />
            <el-tooltip
              :open-delay="700"
              :offset="0"
              :visible-arrow="false"
              popper-class="tooltip"
              effect="dark"
              placement="bottom"
            >
              <NoteInfoIcon class="cursor-pointer" />
              <template #content>
                <div class="text-activeTabColor font-normal text-xs">
                  <span class="font-bold mr-1">1.</span
                  >{{ $t('settings.company.high_security_mode_hint.first') }}
                </div>
                <div class="text-activeTabColor text-xs">
                  <span class="font-bold mr-1">2.</span
                  >{{ $t('settings.company.high_security_mode_hint.second') }}
                </div>
              </template>
            </el-tooltip>
          </div>
          <el-checkbox
            v-model="data.efis_retranslate"
            :label="$t('settings.company.retransmit_efis')"
          />
        </div>
        <div class="flex gap-3 justify-between mt-6">
          <div class="relative">
            <span class="object-basic__info__label input-label">
              {{ $t('settings.company.data_format') }}
            </span>
            <el-select
              v-model="data.dateformat.key"
              :placeholder="$t('select')"
              class="w-full"
            >
              <el-option
                v-for="item in dates"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </div>
          <div class="relative">
            <span class="object-basic__info__label input-label">
              {{ $t('settings.company.time_format') }}
            </span>
            <el-select
              v-model="data.timeformat.key"
              :placeholder="$t('select')"
              class="w-full"
            >
              <el-option
                v-for="item in times"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </div>
        </div>
        <div>
          <div class="radio flex flex-col rounded-xl bg-lightGrey-200 py-3">
            <el-radio
              v-model="currentFormatTime"
              :label="formatTimes[0]"
              class="px-3 pb-3 border-b border-lightGrey-300 m-0"
            >
              {{ formatTimes[0] }}
            </el-radio>
            <el-radio
              v-model="currentFormatTime"
              :label="formatTimes[1]"
              class="px-3 pt-3 m-0"
            >
              {{ formatTimes[1] }}
            </el-radio>
          </div>

          <div class="px-2">
            <p class="font-normal text-grey-200 text-xs leading-5">
              {{ $t('settings.company.note') }}
            </p>
          </div>
        </div>
      </el-form>
    </ModalWrapper>
  </div>
</template>

<script setup>
import { onBeforeMount, ref, watch, getCurrentInstance, onMounted } from 'vue'
import { companyApi } from '@/api'

import NoteInfoIcon from '@/components/companyControlsCenter/assets/icons/black-note.vue'
import ModalWrapper from '@/components/companyControlsCenter/components/ControlsModalWrapper.vue'

import { allRoles } from '@/constants'

import { useI18n } from '@/hooks/useI18n'
import { refs } from '@/components/companyControlsCenter/helpers/index.js'
import { createVuexHelpers } from 'vue2-helpers'

const { useGetters, useActions, useMutations } = createVuexHelpers()

const { getTZ } = useGetters('properties', ['getTZ'])
const { activeCompany, role, activeCompanyId } = useGetters('login', [
  'activeCompany',
  'role',
  'activeCompanyId'
])
const { getMe } = useActions('login', ['getMe'])

const { getFormatTime, updateCompanySettings } = useActions('company', [
  'getFormatTime',
  'updateCompanySettings'
])

const { setFormatTime } = useMutations('company', ['setFormatTime'])

const { integrators } = useGetters('integrators', ['integrators'])
const { getIntegrators } = useActions('integrators', ['getIntegrators'])

const emits = defineEmits(['onClose'])

const $t = useI18n()
const instance = getCurrentInstance()

const data = ref({
  formatTime: ''
})

const loading = ref(false)
const formatTimes = ['00:00:00', '23:59:59']
const currentFormatTime = ref(formatTimes[0])

const dates = ['yyyy-MM-dd', 'd/MM/yy', 'dd-MM-yyyy']
const times = ['HH:mm', 'HH:mm:ss']
const rules = ref({
  name: [
    {
      required: true,
      message: $t('settings.user.errors.name')
    }
  ]
})

const changeFormatTime = (formatTime) => {
  localStorage.setItem('formattedTime', JSON.stringify(formatTime))
  getFormatTime(formatTime)
}

watch(currentFormatTime, (val) => {
  changeFormatTime(val)
  data.value.formatTime = val
  setFormatTime(val)
})

const saveCompanySettings = async () => {
  loading.value = true

  companyApi.settingsCompany(
    activeCompanyId.value,
    { formData: data.value },
    async () => {
      await getMe()
      emits('onClose')

      instance.proxy.$showSuccess($t('settings.company.success.update_company'))
    },
    (err) => {
      instance.proxy.$showError(err.response.data.message)
    },
    () => {
      loading.value = false
    }
  )
}

onBeforeMount(() => {
  if (role.value === allRoles.CONTROLLER) getIntegrators()

  if (activeCompany.value)
    data.value = JSON.parse(JSON.stringify(activeCompany.value))

  const formattedTime = JSON.parse(localStorage.getItem('formattedTime'))

  if (formattedTime) {
    data.value.formatTime = formattedTime
    currentFormatTime.value = formattedTime
  }
})
</script>
<style lang="scss" scoped>
@import '../../assets/styles/CompanySettings.scss';
</style>
