<template>
  <div class="flex items-center group-dots border border-lightGrey-200">
    <div
      class="group-dots__minus flex items-center justify-center bg-blue-100 cursor-pointer"
      @click="changeClusterRadius(true)"
    >
      <MinusIcon />
    </div>
    <div
      class="group-dots__value flex items-center justify-center bg-lightGrey-100"
    >
      {{ clusterRadiusCount }}
    </div>
    <div
      class="group-dots__plus flex items-center justify-center bg-blue-100 cursor-pointer"
      @click="changeClusterRadius(false)"
    >
      <PlusIcon />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import MinusIcon from '../../assets/icons/minus.vue'
import PlusIcon from '../../assets/icons/plus.vue'

import { createVuexHelpers } from 'vue2-helpers'

const { useMutations } = createVuexHelpers()

const { SET_RADIUS_VALUE } = useMutations('map', ['SET_RADIUS_VALUE'])

const clusterRadiusCount = ref(100)

const changeClusterRadius = (value) => {
  if (value && +clusterRadiusCount.value > 10) {
    clusterRadiusCount.value -= 10
  } else if (!value && +clusterRadiusCount.value < 100) {
    clusterRadiusCount.value += 10
  }

  SET_RADIUS_VALUE(clusterRadiusCount.value)

  localStorage.setItem('clusterRadiusCount', clusterRadiusCount.value)
}

onMounted(() => {
  const isClusterExist = JSON.parse(localStorage.getItem('clusterRadiusCount'))

  if (isClusterExist) {
    clusterRadiusCount.value = Number(isClusterExist)
    changeClusterRadius(clusterRadiusCount.value)
  }
})
</script>
<style lang="scss" scoped>
@import '../../assets/styles/GroupDots.scss';
</style>
