<template>
  <div class="flex items-center measure-units border border-lightGrey-400">
    <div
      v-for="unit in measureUnits"
      :key="unit.value"
      :class="[
        'measure-units__item',
        'flex',
        'items-center',
        'justify-center',
        'cursor-pointer',
        'font-semibold',
        'text-base',
        isActive === unit.value
          ? 'bg-blue-200 text-white'
          : 'bg-lightGrey-100 text-black-100'
      ]"
      @click="changeMeasure(unit.value)"
    >
      {{ unit.text }}<sup v-if="unit.hasSup" class="text-sizeTen">2</sup>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'

import { useI18n } from '@/hooks/useI18n'
import { createVuexHelpers } from 'vue2-helpers'

const { useGetters, useMutations } = createVuexHelpers()

const { measure } = useGetters('geozones', ['measure'])
const { SET_MEASURE } = useMutations('geozones', ['SET_MEASURE'])

const $t = useI18n()

const emits = defineEmits(['onChangeMeasurement'])

const isActive = ref('')
const measureUnits = [
  {
    value: 'm',
    text: $t('settings.display.meter'),
    hasSup: true
  },
  {
    value: 'km',
    text: $t('settings.display.kilometer'),
    hasSup: true
  },
  {
    value: 'he',
    text: $t('settings.display.hectar')
  }
]

const changeMeasure = (value) => {
  isActive.value = value

  localStorage.setItem('measureGeo', JSON.stringify(value))
  SET_MEASURE(value)

  emits('onChangeMeasurement', value)
}

onMounted(() => {
  isActive.value = measure.value
})
</script>
<style lang="scss" scoped>
@import '../../assets/styles/MeasurementUnits.scss';
</style>
